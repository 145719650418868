<template>
  <messages-pane />
</template>

<script>
import MessagePane from '@/components/MessagePane.vue';

export default {
  name: 'consultant_messages',
  components: {
    'messages-pane': MessagePane,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "~@/scss/variables.scss";
</style>
